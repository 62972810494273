<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Week {{week.number}} <a href="" v-if="!savedPaymentPlan && !saved && !addedNewWeek" class="text-danger" @click.prevent="$emit('remove-week')">delete</a></h4>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="income">Income</label>
            <input type="text" class="form-control" v-model="week.income" placeholder="Enter income" id="income">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="cost">Internal Cost</label>
            <input type="text" class="form-control" v-model="week.cost" placeholder="Enter income" id="cost">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group" v-if="rawBalance < 0">
            <label for="planInWeeks" class="text-danger">Set payment plan in weeks (optional) </label>
            <input type="number" class="form-control" :disabled="savedPaymentPlan" v-model="week.paymentPlanSpread" placeholder="Enter income" id="planInWeeks">
<!--            <button class="btn btn-danger mt-1" v-if="!savedPaymentPlan" @click="addPaymentPlan" id="pp">save payment plan</button>-->
          </div>
        </div>
        <div class="col-md-6">
        </div>
      </div>

      <div class="mt-1">
        <table class="table">
          <tr>
            <th>Payment schedule (this week) </th>
            <th>Amount to transfer to bank</th>
            <th>Balance</th>
          </tr>
          <tr>
            <td>€ {{displayPaymentPlansForWeek.sum}} <br> <small>{{displayPaymentPlansForWeek.sourceWeeks}}</small></td>
            <td>€ {{transferToBank}}</td>
            <td>€ {{balance}}</td>
          </tr>

        </table>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <button class="btn btn-danger mr-1" v-if="!saved" @click="save">Save week {{week.number}}</button>
          <button class="btn btn-primary"  @click="addNewWeek" v-if="saved && !addedNewWeek">Add new week</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "week",
  props: ['week', "weeks", "accumulatedArrears"],
  data(){
    return {
      balance: 0,
      transferToBank: 0,
      saved: false,
      savedPaymentPlan: false,
      addedNewWeek: false
    }
  },
  computed:{

    absoluteBalance(){
      return this.week.income - this.week.cost >= 0  ? this.week.income - this.week.cost : this.week.cost - this.week.income;
    },

    rawBalance(){
      return this.week.income - this.week.cost;
    },

    displayPaymentPlansForWeek(){
      return this.getPaymentPlansForWeek();
    },

  },
  methods: {

    addPaymentPlan() {
      if(this.savedPaymentPlan){
        this.$store.dispatch('error', {message: 'Payment plan already saved!', showSwal: true})
        return;
      }
      this.$emit('add-payment-plan', this.week.paymentPlanSpread, this.week.number, this.absoluteBalance)
      this.savedPaymentPlan = true;
    },

    addNewWeek() {
      if(!this.saved){
        this.save();
        this.saved = true;
      }

      this.$emit('add-new-week')
      this.addedNewWeek = true
    },

    getPaymentPlansForWeek(){
      const paymentPlans = this.week.paymentPlans
      let sum = 0
      let sourceWeeks = '';
      paymentPlans.forEach(e => {
        sum  = sum + e.amount;
        sourceWeeks = sourceWeeks + 'week ' + e.fromWeek + ' '
      })

      if(sum === 0){
        return {
          sum: 0,
          sourceWeeks: ''
        }
      }
      return {
        sum,
        sourceWeeks
      }
    },

    save(){

      /// just to ensure payment plans are saved
        this.addPaymentPlan()
      //// not part of the algorithm

      let balance = this.rawBalance

      if(balance > 0){
        console.log('balance > 0: ', balance)

        if(this.getPaymentPlansForWeek().sum > 0){

          console.log('getPaymentPlansForWeek > 0: ', this.getPaymentPlansForWeek().sum)

          if(balance > this.getPaymentPlansForWeek().sum){

            console.log('balance > getPaymentPlansForWeek: ', balance, this.getPaymentPlansForWeek().sum)

            let balanceRemaining = balance - this.getPaymentPlansForWeek().sum

            if(this.accumulatedArrears > 0){
              // we attempt to retrieve our accumulated balance
              console.log('this.accumulatedArrears > 0 ')

              if(balanceRemaining > this.accumulatedArrears){
                balanceRemaining =  balanceRemaining - this.accumulatedArrears
                const accumulatedArrears = 0;
                this.$emit('onAccumulatedArrearsChanged', accumulatedArrears);
                this.transferToBank = balanceRemaining

              }else{

                if(balanceRemaining > 0 ){
                  // if remaining balance is not more accumulated but greater than 0
                  const accumulatedArrears = this.accumulatedArrears - balanceRemaining;
                  balanceRemaining = 0;
                  this.transferToBank = 0
                  this.$emit('onAccumulatedArrearsChanged', accumulatedArrears);
                }


              }


            }else{

              console.log('getPaymentPlansForWeek !> 0: ') // user does not have any accumulated arrears
              this.transferToBank = balanceRemaining
            }

          }else{

              console.log('balance !> getPaymentPlansForWeek')
              let debt = this.getPaymentPlansForWeek().sum - balance;
              const accumulatedArrears = this.accumulatedArrears + debt
              this.transferToBank = 0
              this.$emit('onAccumulatedArrearsChanged', accumulatedArrears);
          }

        }
        else{
          // transfer same amount as balance to bank if there are no out standing payment plan and no accumulated arrears
          this.transferToBank = balance;
        }

      }else{

        console.log('balance <= 0: ', balance)

        if(this.getPaymentPlansForWeek().sum > 0){

          console.log('there are arrears for this week ', this.getPaymentPlansForWeek().sum)

          // if payment plan has not been set for that week
          if(this.week.paymentPlanSpread <= 0){

            console.log('theres no payment plan, even when balance is < 0 for this week: ', balance)
            const accumulatedArrears = this.accumulatedArrears + this.getPaymentPlansForWeek().sum + this.absoluteBalance
            this.$emit('onAccumulatedArrearsChanged', accumulatedArrears);

          }else{
            // else
            console.log('there is a payment plan for balance is < 0 for this week: ', balance)
            const accumulatedArrears = this.accumulatedArrears + this.getPaymentPlansForWeek().sum
            this.$emit('onAccumulatedArrearsChanged', accumulatedArrears);

          }



        }else{

          console.log('arrears for this week, when there are no previous areas and no payment plans')

        //

          this.accumulatedArrears = this.accumulatedArrears + this.absoluteBalance
          this.$emit('onAccumulatedArrearsChanged', this.accumulatedArrears);

        }

        this.transferToBank = 0.0

      }

      this.balance = balance;

      this.saved = true;


      // const totalPaymentPlans = this.getPaymentPlansForWeek().sum;
      //
      // console.log('totalPaymentPlans: ' , totalPaymentPlans, 'balance: ', balance)
      //
      // if(this.absoluteBalance >= (totalPaymentPlans)){
      //   this.week.leftToPay =  0.0;
      // }else{
      //   this.week.leftToPay = this.absoluteBalance - totalPaymentPlans ;
      // }
      //
      // this.$store.dispatch('success', { message: 'Week ' + this.week.number + " saved", showSwal: true})

    }


  }
}
</script>

<style scoped>

</style>