<template>
    <div>
      <h4>Accumulated arrears: € {{accumulatedArrears}}</h4>
<!--      <h4>Total outstanding debt: € <small>To be done</small></h4>-->
      <hr>
      <template v-for="week in weeks">
        <week :week="week"
              :weeks="weeks"
              :accumulated-arrears="accumulatedArrears"
              @add-new-week="addNewWeek"
              @remove-week="weeks.splice(weeks.length - 1, 1)"
              @add-payment-plan="addPaymentPlan"
              @onAccumulatedArrearsChanged="onAccumulatedArrearsChanged"
        ></week>
      </template>

    </div>
</template>

<script>
import week from "./week";

export default {
  name: "arrears",
  components: {
    week
  },
  data(){
    return {

      accumulatedArrears: 0,
      weeks: [{
        number: 1,
        income: 0,
        cost: 0,
        bankTransfer: 0,
        paymentPlanSpread: 0,
        paymentPlans: [
          {
            amount: 0,
            fromWeek: 0
          }
        ]
      }],

      paymentPlanInWeeks: [],

    }
  },

  methods: {

    onAccumulatedArrearsChanged(newValue){
      this.accumulatedArrears = newValue;
    },

    addNewWeek() {

      const paymentPlans = [];
      const thisWeekNumber = this.weeks.length + 1;
      // check if there's an existing payment schedule for this week
      const existingPlansFromPreviousWeeks = this.paymentPlanInWeeks.filter(e => thisWeekNumber - e.weekCreated <= e.spread)
      if(existingPlansFromPreviousWeeks){
        existingPlansFromPreviousWeeks.forEach(e => {
          console.log('totalAmountToPay: ', e.totalAmountToPay)
          paymentPlans.push({
            amount: e.totalAmountToPay / e.spread,
            fromWeek: e.weekCreated
          })
        })
      }

      this.weeks.push({
        number: thisWeekNumber,
        income: 0,
        cost: 0,
        bankTransfer: 0,
        paymentPlanSpread: 0,
        paymentPlans: paymentPlans
      })
    },

    addPaymentPlan(spread = 0, weekNo = 0, amountToPay){

      if(spread === 0){
        //this.$store.dispatch('error', { message: 'Please enter payment plan', showSwal: true})
        return;
      }

      this.paymentPlanInWeeks.push({
        weekCreated: weekNo,
        totalAmountToPay: amountToPay,
        spread: spread /// number of weeks to spread over.
      })

      console.log(this.paymentPlanInWeeks);
      //this.$store.dispatch('success', { message: 'payment play added for week ' + weekNo, showSwal: true})
    },


  },

  created() {
    this.$store.dispatch('setPageTitle', 'Arrears Simulation')
  }
}
</script>

<style scoped>

</style>